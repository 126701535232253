<template>
  <v-dialog v-model="showModalDeleteProductoItem" width="480px" persistent lazy scrollable>
    <v-card>
      <v-toolbar color="error" card dark>
        <v-toolbar-title>Eliminar Producto Item</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="replaceShowModalDeleteProductoItem({ status: false })">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <center style="padding:25px 0 20px;">
        <p  v-if="productoItem">¿Está seguro de eliminar el item <strong>{{ productoItem.nombre }}?</strong></p>       
      </center>
      <v-divider />
      <div class="my-3 text-xs-center">
        <v-btn
          :disabled="processingDelete"
          :loading="processingDelete"
          color="error"
          @click="launchDelete"
        >
          Eliminar
        </v-btn>
        <v-btn
          :disabled="processingDelete"
          @click="replaceShowModalDeleteProductoItem({ status: false })"
        >
          Cancelar
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      processingDelete: false
    };
  },

  computed: {
    ...mapState({
      showModalDeleteProductoItem: state => state.productos.showModalDeleteProductoItem,
      productoItem: state => state.productos.productoItem
    })
  },

  watch: {
    showModalDeleteProductoItem(newValue, oldValue) {
      if (!newValue) {
        this.replaceCurrentProductoItem({ productoItem: null });
        return false;
      }
    }
  },

  methods: {
    ...mapActions({
      replaceShowModalDeleteProductoItem: "productos/replaceShowModalDeleteProductoItem",
      replaceCurrentProductoItem: "productos/replaceCurrentProductoItem",
      deleteItem: "productos/deleteItem"
    }),

    launchDelete() {
      this.processingDelete = true;
      this.deleteItem({ itemId: this.productoItem.id })
        .then(response => {
          this.processingDelete = false;
          this.replaceShowModalDeleteProductoItem({ status: false });
          this.$emit("onChange");
        })
        .catch(() => {
          this.processingDelete = false;
        });
    }
  }
};
</script>
